<template>
  <v-container fluid>
    <V-row>
      <v-col md="12">
        <SettingItem
          @loadData="loadData"
          collection="publisher"
          :items="publishers"
          :itemsr="counteries"
          :itemHeader="publisherItemHeader"
        />
      </v-col>
    </V-row>
  </v-container>
</template>

<script>
import SettingItem from "./SettingItem/index";
import ApiService from "@/services/api.service";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    SettingItem,
  },
  data() {
    return {
      publishers: [],
      counteries: [],
      publisherItemHeader: [
        // { text: "id", value: "id" },
        { text: "En Title", value: "english_title" },
        { text: "Ar Title", value: "arabic_title" },
        { text: "Countery", value: "countery_id.arabic_title" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  methods: {
    async loadData(filter) {
      try {
        this.loading = true;
        this.fetchData(filter);
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },

    async fetchData(filter) {
      try {
        console.log(filter);
        this.publishers = [];
        let url =
          "items/publisher?fields=id,arabic_title,english_title,sequence,countery_id.english_title,countery_id.arabic_title,countery_id.id&sort=arabic_title";
        if (filter) {
          url = url + `&filter[countery_id.id]=${filter}`;
        }
        const data = await ApiService.get(url);
        this.publishers = data.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    this.fetchData(null);
    const data = await ApiService.get(
      "items/counteries?fields=id,arabic_title,english_title"
    );
    this.counteries = data.data.data;
  },
};
</script>

<style></style>
